#portfolio {
  min-height: 100vh;
    width: 100%;

}
  
.background {
  background-color: rgb(82,45,128);
  min-height: 100vh;
  width: 100%;
  color: aliceblue;
  padding-bottom: 20px;

}

.active {
    color: orange;
}

.dflex {
    display: flex;
    justify-content:space-evenly;
    margin-right: 20px;
    margin-left: 20px;
}

.head { 
  text-align: center;
  margin: 0px;
}

.subhead {
  margin-left: 20px;
  padding: 20px;

}

.portbutton {
  background-color: rgb(98,128,45);
  color: rgb(90,52,32);
  height: 60px;
  width: auto;
  padding: 0 15px;
  font-size: 1.2em;
  font-weight:600;
  border: none;
  border-radius: 1.5em;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}