.head { text-align: center;
    margin: 0px;
    

}

.dflex {
    display: flex;
    justify-content:space-evenly;
    margin-left: 20px;
    margin-right: 20px;
}

#home {
    min-height: 50vh;
    width: 100%
  
  }

  .background {
    min-height: 50vh;
    width: 100%;
    background-color: rgb(196,175,255);
    color: rgb(51,24,50);

  }







