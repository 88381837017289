.header { text-align: center;
            color: aliceblue;
            margin: 0%;
}

.dflex {
    display: flex;
    justify-content:space-between;
}

#resume {
    min-height: 100vh;
    width: 100%
}

.resume {
        height: 100vh;
        margin-bottom: 15px;
        width: 100%;
  }

  .background {
    background-color: rgb(82,45,128);
    min-height: 100vh;
    width: 100%;
    color: aliceblue;
  
  }


.btn {
    background-color: rgb(98,128,45);
    color: rgb(90,52,32);
    height: 60px;
    width: auto;
    padding: 0 15px;
    font-size: 1.2em;
    font-weight:600;
    border: none;
    border-radius: 1.5em;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}