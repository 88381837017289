.dflex {
    display: flex;
    justify-content:space-between;
}

#contact {
    min-height: 50vh;
    width: 100%
  
  }

  .background {
    min-height: 50vh;
    width: 100%;
    background-color: rgb(196,175,255);
  
}

.contact
{
    background-image: radial-gradient(circle  at  80% 100%, rgba(216, 30, 91, 0.7) 33%, transparent 33%),
    radial-gradient(circle  at  0% 0%, rgba(216, 30, 91, 0.7) 20%, transparent 20%);
    width: 100%;
    margin: 0px;
}

.header
{
    color:rgb(51,24,50);
    margin: 0px;
    text-align: center;
}

.icons
{
    height: 100px;
    width: auto;
}

.contactList
{
    list-style: none;
    display: flex;
    gap:10px;
}

.contactActions
{
    display:flex;
    justify-content:space-around;
}